import React from 'react';
import PropTypes from 'prop-types';
import { getBackgroundByShortName, getShortName } from 'Chore/Utils';

const Avatar = ({ avatar='', name='', width='size-32', radius='full' }) => {
  const showName = () => {
    return avatar ? 'avatar' : 'name';
  };

  return (showName() === 'avatar' ?
    <img alt="failed" className={`v-w-${width} v-h-${width} v-rounded-${radius}`} src={avatar} /> :
    <div className={`v-w-${width} v-h-${width} v-rounded-${radius} v-flex v-items-center v-justify-center v-${getBackgroundByShortName(getShortName(name))}`}>{getShortName(name)}</div>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  radius: PropTypes.string
};

export default Avatar;
