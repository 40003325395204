import React, { useContext } from 'react';
import { HubContext } from 'Redux/Context/context';
import { VegaFlex } from "@heartlandone/vega-react";
import ActivityListItem from 'Components/ActivityListItem/ActivityListItem';

function MyActivity() {
  const { myActivitiesState } = useContext(HubContext);

  return (
    <VegaFlex direction={'col'} gap={'size-16'}>
        {/*  use a loop to render the  <ActivityListItem /> */}
        {myActivitiesState.activities?.map((activity, index) => {
          return <ActivityListItem key={index} activity={activity} module={myActivitiesState.module}/>;
        })}
    </VegaFlex>
  );
}

export default MyActivity;
