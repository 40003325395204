import { TOGGLE_MODAL } from '../Actions/actionName'

const FormReducer = (state = '', action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return action.isShow;
    default:
      return false;
  }
};

export default FormReducer;
