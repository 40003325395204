import React, { useState, useContext } from 'react';
import { VegaChip, VegaFlex, VegaIcon, VegaText,VegaButtonLink} from "@heartlandone/vega-react";
import imgSrc from "Imgs/activity_bg.png"
import { HubContext } from 'Redux/Context/context';
import { _fetch } from 'Chore/Request';
import { useNavigate } from 'react-router-dom';
import { toggleGlobalModalAction } from 'Redux/Actions/actions';
import dayjs from 'dayjs';

import { PATH } from 'Chore/Apis';
const { GET_ACTIVITIES } = PATH;
function ListItem(props) {
  const navigate = useNavigate();

  const { activity } = props;
  const { globalModalDispatch } = useContext(HubContext);
  const [isFavorite, setIsFavorite] = useState(false);
  const { id = 0, address, chargeAmount = 0, city, endTime, isAllowInviteGuests, maxInvitedGuests, registetNumberLimit = 0, startTime, title } = activity;
  
  const onNavigateActivityDetail = (activityId) => {
    navigate('/activityInfo/'+ activityId, {
      state: {
        activityId: activityId
      }
    });
  };

  const _toggleFavoriteState = () => {
    _toggleModal(true);
    _fetch({
      api: `${GET_ACTIVITIES}`,
      method: 'GET'
    }).then(() => {
      setIsFavorite(!isFavorite);
    }).finally(() => {
      _toggleModal();
    })
  };

  const _toggleModal = (isShow = false) => {
    globalModalDispatch(toggleGlobalModalAction({isShow}));
  }

  return (
    <VegaFlex className='v-border-divider-secondary v-rounded-12 v-pb-size-16 oveflow-hidden' gap={'size-16'} direction={'col'}>
      <div className='thumb-container'>
        <div className='activiy-summary-cover' style={{
            backgroundImage: `url(${imgSrc})`,
            width: '100%',
            height: 230,
            backgroundSize: 'cover',
        }}></div>
        <div className='location w-full'>
            <VegaFlex justifyContent={'space-between'} alignItem={'center'}>
                <VegaChip text={`${city}`} icon='location-pin' size={'small'} bgColor='bg-primary'></VegaChip>
                <VegaFlex
                  className={`v-rounded-full p-8 bg-light-primary pointer`}
                  style={{ width: 32, height: 32 }}
                  onClick={_toggleFavoriteState}
                  justifyContent={'center'} alignItems={'center'}>
                  <VegaIcon className={`${isFavorite ? 'font-dark-Background-Accent6-Primary' : 'font-light-Background-Toogle-Switch'}`} icon='fa-solid fa-heart' variant="icon-only" size="size-24" iconColor='text-error'></VegaIcon>
                </VegaFlex>
            </VegaFlex>
        </div>
      </div>
      <VegaFlex direction={'col'} className='p-x-16' gap={'size-12'}>
        <VegaFlex justifyContent={'space-between'} alignItem={'center'}>
          <VegaButtonLink className="vega-link font-weight-bold v-flex v-justify-between"  onVegaClick={() => onNavigateActivityDetail(`${id}`)}>{title}</VegaButtonLink>
          <VegaChip text="New" size={'extra-small'} bgColor='bg-accent5-tertiary'></VegaChip>
        </VegaFlex>
        <VegaText className="v-font-h6 v-text-success">${chargeAmount}</VegaText>
        <VegaFlex alignItem={'center'} gap={'size-12'}>
          <VegaChip text={`${registetNumberLimit}`} size={'extra-small'} bgColor='bg-chip' icon='user-group'></VegaChip>
          <VegaChip text="6" size={'extra-small'} bgColor='bg-chip' icon='link'></VegaChip>
          {
              isAllowInviteGuests && <VegaChip text={`Allow ${maxInvitedGuests} Guests`} size={'extra-small'} bgColor='bg-chip'></VegaChip>
          }
        </VegaFlex>
        <div className="v-bg-divider h-1"></div>
        <VegaFlex alignItem={'center'} gap={'size-12'}>
          <VegaIcon icon='clock-face' size="size-16" color='text-primary'></VegaIcon>
          <span className='v-font-field-label-sm v-text-primary'>{dayjs(startTime).format('D/MMM/YYYY, H:m')} - {dayjs(endTime).format('D/MMM/YYYY, H:m')}</span>
        </VegaFlex>
        <VegaFlex alignItem={'center'} gap={'size-12'}>
          <VegaIcon icon='location-pin' size="size-16" color='text-primary'></VegaIcon>
          <span className='v-font-field-label-sm v-text-primary'>{address}</span>
        </VegaFlex>
      </VegaFlex>
    </VegaFlex>
  );
}

export default ListItem;
