import React, { useState } from 'react';
import { VegaFlex } from "@heartlandone/vega-react";

const Carousel = (props) => {
  const { direction = 'row', height = 670, urls = ['red', 'green', 'black', 'grey', 'yellow'] } = props;
  const [main, setMain] = useState(urls[0]);
  const _onClick = (url) => {
    setMain(url)
  }
  const _renderitems = () => {
    return urls.map((url) => {
      return <div
        className='pointer round-8'
        onClick={() => _onClick(url)}
        style={{ backgroundColor: url, width: '100%', height: '30%', marginBottom: 16 }}>
      </div>
    })
  }

  return (
    <VegaFlex direction={direction} className='w-full' gap={'size-16'}>
        <div className='round-16' style={{ height, width: '75%', backgroundColor: main }}></div>
        <div style={{ height, width: '25%', overflowY: 'scroll' }}>
            {_renderitems()}
        </div>
    </VegaFlex>
  );
}

export default Carousel;
