import {
  createHashRouter
  } from "react-router-dom";

import App from './App';
import { ROUTES_PATH, DYNAMIC_SEGMENT } from 'Chore/Constants';
import ActivityList from './Views/ActivityList/List'
import MyActivity from 'Views/MyActivity/MyActivity';
import MyActivityLoader from 'RouteLoader/App/MyActivityLoader';
import MyPublishedActivitiesLoader from 'RouteLoader/App/MyPublishedActivitiesLoader';
import ActivityDetail from './Views/ActivityDetail';
import ViewMyRegisteredActivityDetail from 'Views/MyActivity/ViewMyRegisteredActivityDetail';
import ViewMyPublishedActivityDetail from 'Views/MyActivity/ViewMyPublishedActivityDetail';

const router = createHashRouter([
  {
    path: ROUTES_PATH.ROOT,
    element: <App />,
    children: [
      {
        index: true,
        element: <ActivityList />,
      },
      {
        path: ROUTES_PATH.ACTIVITY_INFO,
        element: <ActivityDetail/>,
        loader: MyActivityLoader
      },
      {
        path: ROUTES_PATH.MY_ACTIVITY,
        element: <MyActivity />,
        loader: MyActivityLoader
      },
      {
        path: ROUTES_PATH.MY_ACTIVITY_REGISTERED_DETAIL,
        element: <ViewMyRegisteredActivityDetail />,
        loader: MyActivityLoader
      },
      {
        path: `${ROUTES_PATH.MY_ACTIVITY_PUBLISHED_DETAIL}${DYNAMIC_SEGMENT.ID}`,
        element: <ViewMyPublishedActivityDetail />,
        loader: MyPublishedActivitiesLoader
      }
    ],
  }
]);

export default router;
