import React, { useState, useEffect, useContext} from 'react';
import { VegaFlex, VegaModal,VegaButtonLink,VegaGrid, VegaButton, VegaInput, VegaTextarea} from "@heartlandone/vega-react";
import { HubContext } from 'Redux/Context/context';
import { FORM_NAME } from 'Chore/Constants';
import { openFormAction } from 'Redux/Actions/actions';
import ActivityListItem from 'Components/ActivityListItem/ActivityListItem';
import Guest from 'Components/Register/Guest';

function RegisterForm({ name = ''}) {
  const { formDispatch } = useContext(HubContext);

  const [isOpen, setIsOpen] = useState(false);
  
  const [guests, setguests] = useState([]);

  const addComponent = () => {
    guests.push(1);
    const arr = [...guests];    
    setguests(arr);
  }


  useEffect(() => {
    setIsOpen(name === FORM_NAME['REGISTER_FORM']);
  }, [name]);

  const _closeModal = () => {
    formDispatch(openFormAction({ name: '' }));
  }

  return (
    <VegaModal  open={isOpen} modalTitle='Register Now' className='w-800' style={{ margin: '0 auto' }} onVegaClose={_closeModal} backdrop='static' size={"100%"}>


      <ActivityListItem activity={{}} />
      <VegaFlex direction={'row'} style={{ width: '100%' }} className='m-t-24 m-b-24'>
          <h2 className='m-0'>Your Register Details</h2>
     </VegaFlex>
      <VegaFlex direction="col" gap="size-24">
          <VegaFlex gap="size-24">
              <VegaFlex alignItems={"end"} gap="size-24">
               <VegaInput label="Name" size="small" data-vega-form={"registerName"} required={true} style={{ width: 350 }}/>     
               <VegaInput label="PhoneNumber" size="small" data-vega-form={"registerPhoneNumber"} style={{ width: 350 }} />                    
              </VegaFlex>
          </VegaFlex>
          <VegaFlex  direction="col" gap="size-24">
           <VegaInput label="Email" size="small" type="email" data-vega-form={"registerEmail"} style={{width: 730 }}/>  
          </VegaFlex>
          <VegaFlex >
            <VegaTextarea label="Note" size="small" data-vega-form={"registerNote"} style={{width: 730 }} />
          </VegaFlex>
      </VegaFlex>
      <VegaGrid row={`${guests.length}`} gap={'size-16'}>
          {guests?.map((index) => {
                return <Guest index={index}></Guest>;
            })}
      </VegaGrid>
      <VegaButtonLink label="Add Guest"  onVegaClick={() => addComponent()  } className='m-t-16' icon="far fa-plus"/>
      <VegaFlex justifyContent='end' alignItem={'center'} className='m-t-80 p-t-20 p-l-32 p-r-32 p-b-20 shadow-top border-none' style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <VegaFlex gap={'size-12'}>
          <VegaButton variant="secondary" onVegaClick={_closeModal}>Cancel</VegaButton>
          <VegaButton  label = {"Register"} />
        </VegaFlex>
      </VegaFlex>
    </VegaModal>
  );
}

export default RegisterForm;
