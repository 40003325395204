import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  VegaFlex,
  VegaModal,
  VegaForm,
  VegaButton,
  VegaProgressTracker,
} from "@heartlandone/vega-react";
import { HubContext } from 'Redux/Context/context';
import { FORM_NAME } from 'Chore/Constants';
import StepOne from './ActivityForm/StepOne';
import StepTwo from './ActivityForm/StepTwo';
import StepThree from './ActivityForm/StepThree';
import { openFormAction } from 'Redux/Actions/actions';
import { loadFromLocalStorage } from 'Chore/Utils';
import {_fetch} from 'Chore/Request';
import { PATH } from 'Chore/Apis';
const { POST_ACTIVITY } = PATH;

const FINAL_STEP = 2

function ActivityForm({ name = '', finalStep = FINAL_STEP }) {
  const { formDispatch } = useContext(HubContext);
  const vegaForm = useRef();
  const vegaProgressTracker = useRef();
  const [currentStep, setCurrentStep] = useState(0);
  const [form, setForm] = useState(loadFromLocalStorage('activityForm'));
  const [isOpen, setIsOpen] = useState(false);
  const [isStepOneDisabled, setIsStepOneDisabled] = useState(false);
  const [progressTrackerStatus, setProgressTrackerStatus] = useState(false);

  useEffect(() => {
    vegaForm.current.setValue(form);
  }, [form]);

  useEffect(() => {
    setIsOpen(name === FORM_NAME['ACTIVITY_FORM']);
  }, [name]);

  const _closeModal = () => {
    formDispatch(openFormAction({ name: '' }));
  }

  const _goToNextStep = () => {
    vegaForm.current.getValue().then(c => {
      setForm({ ...form, ...c});
      vegaProgressTracker.current.nextStep();
      setCurrentStep(currentStep + 1);
    });

    console.log('go to next step');
  }

  const _onStepClick = () => {
    vegaProgressTracker.current.getCurrent().then(c => {
      setCurrentStep(c);
    });
  }

  useEffect(() => {
    let trackerStatus = false;
    if(currentStep === 0 && isStepOneDisabled)
    {
      trackerStatus =  false;
    }
    else
      trackerStatus = true;

    setProgressTrackerStatus(trackerStatus);
  }, [currentStep, isStepOneDisabled]);

  const _onSwitchChanged = (key, value) => {
    console.log(key, value);
    setForm({ ...form, [key]: value});
  }

  const getValue = (value) => {  
    if (value === null || value === "" || typeof value === 'undefined')
      return false;
    else return true;
  }

  const _submit = () => {
    console.log('this is the final step');
    const formData = {
      title: form?.activityTitle,
      startTime : new Date(form?.startDate?.date +' ' +form?.startDate?.time),
      endTime : new Date(form?.endDate?.date + ' ' + form?.endDate?.time),
      registerDeadline: new Date(form?.deadlineDate?.date +' ' +form?.deadlineDate?.time),
      chargeType: form?.charge?.type,
      chargeAmount : form?.charge?.value,
      city: form?.activityLocation?.city,
      address: form?.activityLocation?.detail,
      registetNumberLimitType: form?.numberLimit?.type,
      registetNumberLimit: form?.numberLimit?.value,
      description: "abcde",
      vedioUrl: [form?.vedioUrl],
      isCollectInformation: getValue(form?.isAllowedCollectInfo?.value),
      isUserNameReqiured: getValue(form?.guestInfo?.username.value ? true : false),
      isPhoneReqiured: getValue(form?.guestInfo?.phone ? true : false),
      isEmailReqiured: getValue(form?.guestInfo?.email ? true : false),
      isNoteReqiured: getValue(form?.guestInfo?.note ? true : false),
      isAllowInviteGuests: getValue(form?.isAllowedInviteGuests?.value),
      maxInvitedGuests: form?.maxInvitedNumber
    }
    
    _fetch({
      method: 'POST',
      data: formData,
      api: `${POST_ACTIVITY}`,
    }).then(res => {
      console.log(res);
    });
  }

  const _getValidState = (stepOneExportVariable) => {

    setIsStepOneDisabled(!stepOneExportVariable[0]);
    form['coverSrcId'] = stepOneExportVariable[1];
  }

  return (
    <VegaModal
      open={isOpen}
      modalTitle='Add New Activity'
      className='w-800'
      style={{ margin: '0 auto' }}
      onVegaClose={_closeModal}
      backdrop='static'
    >
      <VegaProgressTracker
        ref={vegaProgressTracker}
        style={{
          margin: '0 -24px 0 -24px',
          backgroundColor: 'rgb(245,247,247)',
          padding: '16px 0 16px 0',
        }}
        steps={[
          "Activity Info",
          "Register Info",
          "Confirmation"
        ]}
        direction='horizontal'
        onVegaCurrentStepUpdate={_onStepClick}
        canClickStep= {progressTrackerStatus}
      />
      <VegaForm
        data-vega-form={`form`}
        ref={vegaForm}
      >
        <StepOne currentStep={currentStep} callback={_getValidState}/>
        <StepTwo currentStep={currentStep} callback={_onSwitchChanged} fields={{
          isAllowedCollectInfo: !!form.isAllowedCollectInfo,
          isAllowedInviteGuests: !!form.isAllowedInviteGuests
        }}/>
        <StepThree currentStep={currentStep} detailedForm ={form}/>
      </VegaForm>
      <VegaFlex justifyContent='end' alignItem={'center'} className='m-t-80 p-t-20 p-l-32 p-r-32 p-b-20 shadow-top border-none' style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <VegaFlex gap={'size-12'}>
          <VegaButton variant="secondary" onVegaClick={_closeModal}>Close</VegaButton>
          {currentStep !== finalStep && <VegaButton disabled={isStepOneDisabled} onVegaClick={_goToNextStep} label = {"Next"} />}
          {currentStep === finalStep && <VegaButton onVegaClick={_submit}  label = {"Publish"} />}
        </VegaFlex>
      </VegaFlex>
    </VegaModal>
  );
}

export default ActivityForm;
