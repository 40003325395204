import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { VegaFlex, VegaButtonLink, VegaPagination } from "@heartlandone/vega-react";
import { FORM_NAME, FILTER_CONTROL_CATEGORY, ROUTES_PATH, PAGINATION_OPTIONS, FILTER_OPTIONS } from 'Chore/Constants';
import { _fetch } from 'Chore/Request';
import { HubContext } from 'Redux/Context/context';
import { openFormAction } from 'Redux/Actions/actions';
import { toggleGlobalModalAction, setGlobelActivitiesAction, setMyActivitiesAction } from 'Redux/Actions/actions';
import FilterGroup from 'Components/FilterGroup/FilterGroup';
import MainMenu from 'Components/MainMenu/MainMenu';
import { PATH } from 'Chore/Apis';

import './ActivityList/module.css';

const { GET_ACTIVITIES } = PATH;
const { GP_LIST } = PAGINATION_OPTIONS;
const ACTIVITY_QUERY_PARAMS = {};
const { PERIOD, PAYMENT } = FILTER_OPTIONS;
let module = 'REGISTERED';
let _category = 'Registered';

function ContentView() {
  const { globalModalDispatch, globalActivitiesDispatch, myActivitiesDispatch } = useContext(HubContext);

  const location = useLocation();
  const { formDispatch } = useContext(HubContext);
  const [menuGroup, setMenuGroup] = useState([]);
  const [header, setHeader] = useState('GP Activity List (6)');
  const [total, setTotal] = useState(0);

  const getMyActivities = (category = 'registered', index) => {
    ACTIVITY_QUERY_PARAMS['Category'] = category;
    _category = category;
    module = category.toUpperCase();
    _loadActivity(ACTIVITY_QUERY_PARAMS);
  }

  const _isShow = () => !location.pathname.includes('activityInfo') ;

  useEffect(() => {
    // Google Analytics
    if (location.pathname === ROUTES_PATH.ROOT) {
      setHeader('GP Activity List (6)')
      setMenuGroup([]);
      for (const property in ACTIVITY_QUERY_PARAMS) {
        delete ACTIVITY_QUERY_PARAMS[`${property}`];
      }
      ACTIVITY_QUERY_PARAMS['Category'] = 'Published';
      _loadActivity(ACTIVITY_QUERY_PARAMS);
    } else if (location.pathname === ROUTES_PATH.MY_ACTIVITY) {
      setMenuGroup([{
        label: 'My Registered Activity',
        category: 'Registered',
        callBack: getMyActivities
      }, {
        label: 'My Published Activity',
        category: 'Published',
        callBack: getMyActivities
      }, {
        label: 'My Favorite Activity',
        category: 'Favorite',
        callBack: getMyActivities
      }]);
      setHeader('My Favorite Activity List');
      ACTIVITY_QUERY_PARAMS['Category'] = _category;
      _loadActivity(ACTIVITY_QUERY_PARAMS);
    } else if(location.pathname.includes(ROUTES_PATH.MY_ACTIVITY_PUBLISHED_DETAIL)){
      setMenuGroup([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const _onFilterChange = (e, label) => {
    ACTIVITY_QUERY_PARAMS[`${label}`] = e.detail;
    _loadActivity(ACTIVITY_QUERY_PARAMS);
  }

  const _loadActivity = (params) => {
    _toggleModal(true);
    _fetch({
      api: `${GET_ACTIVITIES}`,
      params : {
        PageIndex: 1,
        PageSize: GP_LIST.SPAN,
        ...params,
      }
    }).then((res) => {
      if (location.pathname === ROUTES_PATH.MY_ACTIVITY) {
        myActivitiesDispatch(setMyActivitiesAction({ activities: res?.payload?.items || [], module }));
      } else {
        globalActivitiesDispatch(setGlobelActivitiesAction({ activities: res?.payload?.items || []}));
      }
      const timer = setTimeout(() => {
        setTotal(res?.payload?.totalCount);
        clearTimeout(timer);
      }, 200);
    }).finally(() => {
      _toggleModal();
    })
  }

  const _toggleModal = (isShow = false) => {
    globalModalDispatch(toggleGlobalModalAction({isShow}));
  }

  const OnAddActivityButtonClick = (e) => {
    e.preventDefault();
    formDispatch(openFormAction({ name: FORM_NAME['ACTIVITY_FORM'] }));
  }

  const _onPageIndexClick = (e) => {
    _loadActivity({
      ...ACTIVITY_QUERY_PARAMS,
      PageIndex: e.detail
    });
  }

  return (
    <MainMenu menuGroup={menuGroup}>
      <div style={{ width: '100%', minHeight: 'calc(100vh - 300px)' }} className='bg-white p-24 round-12'>
        {
          !location.pathname.includes('activityInfo') && <>
            <VegaFlex className='m-b-24' justifyContent="space-between" alignItems="center">
              <h4 className='m-0 f-size-20 f-weight-700 font-normal line-height-28'>{header}</h4>
            </VegaFlex>
            <VegaFlex className='m-b-24' justifyContent="space-between" alignItems="center">
              <FilterGroup key={new Date()} options={[{
                type: FILTER_CONTROL_CATEGORY.INPUT,
                prefixIcon: 'fa-duotone fa-magnifying-glass',
                isRequired: false,
                placeholder: 'search',
                onChangeHandler: (e) => _onFilterChange(e, 'keyword')
              }, {
                type: FILTER_CONTROL_CATEGORY.SELECT,
                prefixIcon: 'fa-duotone fa-magnifying-glass',
                isRequired: false,
                placeholder: '',
                source: PERIOD,
                onChangeHandler: (e) => _onFilterChange(e, 'period')
              }, {
                type: FILTER_CONTROL_CATEGORY.SELECT,
                prefixIcon: 'fa-duotone fa-magnifying-glass',
                isRequired: false,
                placeholder: '',
                source: PAYMENT,
                onChangeHandler: (e) => _onFilterChange(e, 'isFree')
              }]}/>
              {
                location.pathname === ROUTES_PATH.ROOT && <VegaButtonLink
                  label="Add New"
                  icon="far fa-plus"
                  onVegaClick={OnAddActivityButtonClick}
                />
              }
            </VegaFlex>
          </>
        }
        <Outlet/>
        <VegaFlex  className={`m-t-16${_isShow() ? "" : " display-none"}`} justifyContent='end'>
          <VegaPagination
            total={total}
            pageSize={GP_LIST.SPAN}
            extensions={[
              ["currentPageLabel", "pageSelector"],
            ]}
            onVegaChange={_onPageIndexClick}
          />
        </VegaFlex>
      </div>
    </MainMenu>
  );
}

export default ContentView;
