import React from "react";
import {
  VegaFlex,
  VegaForm,
  VegaFont,
  VegaInputSelect,
  VegaInput,
  VegaDatePicker,
  VegaTimePicker,
  VegaToggleSwitch,
  VegaButtonLink,
  VegaImageUploader,
} from "@heartlandone/vega-react";
import {registedDropdownOptions, chargeDropdownOptions,locationDropdownOptions } from "Chore/Constants";
import {
  ClassicEditor,
  Context,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  ContextWatchdog,
	Link,
  Alignment
} from "ckeditor5";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
import "ckeditor5/ckeditor5.css";
import { useState, useEffect, useRef } from 'react';
import {_fetch} from 'Chore/Request';
import { PATH } from 'Chore/Apis';
const { ATTACHMENT } = PATH;
const FIRST_STEP = 0;

function StepOne({ currentStep, callback = () => {} }) {
  const _isShow = () => currentStep === FIRST_STEP;
  const [isTitleValid, setIsTitleValid] = useState(false);
  const [isLocationValid, setLocationValid] = useState(false);
  const [isStartDateValid, setIsStartDateValid] = useState(false);
  const [isEndDateValid, setIsEndDateValid] = useState(false);
  const activityStartDate = useRef()

  const [coverSrc, setCoverSrc] = useState('');

  const validateTitle =[{
    canEvaluate: (input, status) => {
      return typeof input === "string" && !status.disabled && status.visible;
    },
    evaluate: (input) => {
      const isValid = input.trim().length > 0;
      setIsTitleValid(isValid);
      return { isValid };
    }
  }];

  const validateStartDate =[{
    canEvaluate: (input, status) => {
      return typeof input === "string" && !status.disabled && status.visible;
    },
    evaluate: (input) => {
      let startDate = new Date(input.trim()); 
      let now = new Date(); 
      let isValid = startDate >= now;
      if(isValid)
      {
        activityStartDate.current = startDate;
      }
      else
        activityStartDate.current = ""

      setIsStartDateValid(isValid);
      return { isValid };
    }
  }];

  const validateEndDate =[{
    canEvaluate: (input, status) => {
      return typeof input === "string" && !status.disabled && status.visible;
    },
    evaluate: (input) => {
      let endDate = new Date(input.trim()); 
      let startDate = new Date(activityStartDate);
      const isValid = endDate >= startDate;
      setIsEndDateValid(isValid);
      return { isValid };
    }
  }];
  
  const validateLocation =[{
    canEvaluate: (input, status) => {
      return typeof input === "string" && !status.disabled && status.visible;
    },
    evaluate: (input) => {
      const isValid = input.trim().length > 0;
      setLocationValid(isValid)
      return { isValid };
    }
  }];

  useEffect(() => {
    callback([isTitleValid && isLocationValid && isStartDateValid && isEndDateValid, coverSrc]);
  }, [isTitleValid, isLocationValid, isStartDateValid, isEndDateValid, coverSrc, callback]);


  const onChange = async (evt) => {
    if (evt.detail) {
     
      _fetch({
        method: 'POST',
        file: evt.detail,
        api: `${ATTACHMENT}`,
      }).then(res =>{
        let coverId = res?.payload?.id;
        console.log(coverId);
        setCoverSrc(coverId);
      }
      );
    }
  };

  return (
    <div className={`m-t-32${_isShow() ? "" : " display-none"}`}>
      <div className="content-wrapper m-t-24">
        <VegaFlex direction="col" gap="size-24">
          <VegaInput
            label="Activity Title"
            size="small"
            validationRules={validateTitle}
            data-vega-form={"activityTitle"}
            required={true}
          />      
          <VegaFlex direction="col" alignItems={"start"} gap="size-16">
            {/* <VegaFont>StartDate</VegaFont> */}
            <VegaForm data-vega-form={`startDate`}>
              <VegaFlex alignItems={`${isStartDateValid ? "end" :  "center"}`} gap="size-16">
                <VegaDatePicker
                  style={{
                    width: 240,
                  }}
                  size="small"
                  data-vega-form={"date"}
                  required={true}
                  label="StartDate"
                  validationRules={validateStartDate}
                />
                <VegaTimePicker
                  size="small"
                  style={{
                    width: 160,
                  }}
                  data-vega-form={"time"}
                />
              </VegaFlex>
            </VegaForm>
          </VegaFlex>
          <VegaFlex direction="col" alignItems={"start"} gap="size-16">
            <VegaFont>EndDate</VegaFont>
            <VegaForm data-vega-form={`endDate`}>
              <VegaFlex alignItems={"start"} gap="size-16">
                <VegaDatePicker
                  size="small"
                  style={{
                    width: 240,
                  }}
                  data-vega-form={"date"}
                  required={true}
                  validationRules={validateEndDate}
                />
                <VegaTimePicker
                  size="small"
                  style={{
                    width: 160,
                  }}
                  data-vega-form={"time"}
                />
              </VegaFlex>
            </VegaForm>
          </VegaFlex>
          <VegaFlex alignItem={"end"}>
            <VegaForm data-vega-form={`deadlineDate`}>
              <VegaFlex alignItems={"end"} gap="size-16">
                <VegaDatePicker
                  size="small"
                  style={{
                    width: 240,
                  }}
                  label="Register Deadline (optional)"
                  data-vega-form={"date"}
                />
                <VegaTimePicker
                  size="small"
                  style={{
                    width: 160,
                  }}
                  data-vega-form={"time"}
                />
              </VegaFlex>
            </VegaForm>
          </VegaFlex>
          <VegaFlex alignItem={"end"}>
            <VegaForm data-vega-form={`numberLimit`}>
              <VegaFlex alignItems={"end"} gap="size-16">
                <VegaInputSelect
                  style={{
                    width: 240,
                  }}
                  size="small"
                  gap="size-24"
                  source={registedDropdownOptions}
                  label="Register Number Limit"
                  data-vega-form={"type"}
                />
                <VegaInput
                  type="number"
                  size="small"
                  style={{
                    width: 160,
                  }}
                  data-vega-form={"value"}
                />
              </VegaFlex>
            </VegaForm>
          </VegaFlex>
          <VegaFlex alignItem={"end"}>
            <VegaForm data-vega-form={`charge`}>
              <VegaFlex alignItems={"end"} gap="size-16">
                <VegaInputSelect
                  style={{
                    width: 240,
                  }}
                  size="small"
                  gap="size-24"
                  source={chargeDropdownOptions}
                  label="Charge"
                  data-vega-form={"type"}
                />
                <VegaInput
                  style={{ width: 160 }}
                  size="small"
                  data-vega-form={"value"}
                />
              </VegaFlex>
            </VegaForm>
          </VegaFlex>
          <VegaFlex>
            <VegaToggleSwitch
              size="default"
              label="Online Activity"
              data-vega-form={"isOnlineActivity"}
            />
          </VegaFlex>
          <VegaFlex  direction="col" alignItems={"end"} gap="size-16">
            <VegaForm data-vega-form={`activityLocation`}>
              <VegaFlex alignItems={"end"} gap="size-16">
                <VegaInputSelect
                  style={{
                    width: 240,
                  }}
                  size="small"
                  gap="size-24"
                  source={locationDropdownOptions}
                  value="Xian"
                  data-vega-form={"city"}
                  label="Activity Location"
                  required={true}
                />
                <VegaInput
                  size="small"
                  gap="size-24"
                  style={{
                    width: 480,
                  }}
                  data-vega-form={"detail"}
                  validationRules={validateLocation}
                />
              </VegaFlex>
            </VegaForm>
          </VegaFlex>
          <VegaFlex alignItem={"end"} gap="size-16">
            <VegaButtonLink label="Add Activity Location" icon="far fa-plus" />
          </VegaFlex>
          <VegaFlex direction="col" gap="size-16" data-vega-form={"cover"}>
            <VegaForm data-vega-form={"Cover"}>
              <VegaImageUploader 
              label="Cover"
              onVegaChange={onChange}/>    
            </VegaForm>
          </VegaFlex>
          <VegaFlex direction="col" gap="size-16">
            <VegaFont>Activity Description</VegaFont>
            <VegaForm data-vega-form={"description"}>
              <CKEditorContext
                context={Context}
                contextWatchdog={ContextWatchdog}
              >
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    plugins: [Essentials, Bold, Italic, Paragraph, Link, Alignment],
                    toolbar: { 
                      items: [
                        'undo', 'redo',
                        '|',
                        'heading',
                        '|',
                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                        '|',
                        'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                        '|',
                        'link', 'uploadImage', 'blockQuote', 'codeBlock',
                        '|',
                        'alignment',
                        '|',
                        'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                    ],
                  shouldNotGroupWhenFull: true},
                  }}
                  data=""
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor 1 is ready to use!", editor);
                  }}
                />
              </CKEditorContext>
            </VegaForm>
          </VegaFlex>
          <VegaInput
            label="Vedio URL"
            required={false}
            size="small"
            data-vega-form={"vedioUrl"}
          />
          <VegaFlex alignItem={"end"} gap="size-16">
            <VegaButtonLink label="Add Vedio URL" icon="far fa-plus" />
          </VegaFlex>
          <VegaFlex direction="col" gap="size-16">
            <VegaFont> Add Document</VegaFont>
            <VegaImageUploader/>
          </VegaFlex>
          <VegaFlex direction="col" gap="size-16">
          <VegaFont> Add Image</VegaFont>
            <VegaImageUploader />
          </VegaFlex>
        </VegaFlex>
      </div>
    </div>
  );
}

export default StepOne;
