import { OPEN_FORM } from '../Actions/actionName'

const FormReducer = (state = '', action) => {
  switch (action.type) {
    case OPEN_FORM:
      return action.name;
    default:
      return '';
  }
};

export default FormReducer;
