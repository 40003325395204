import { getQuerys } from './Utils';

export const _fetch = async ({ api, data = {}, method = 'GET', options = {
  
}, params = {}, headers = {
  'Content-Type': 'application/json;charset=utf-8',
  'Authorization': sessionStorage.getItem('token') || '',
}, file = null }) => {

  let formData = null;
  let _method = method;
  let _headler = {
    ...headers
  };

  if (file) {
    formData = new FormData();
    formData.append('file', file);
    _method = 'POST';
    delete _headler['Content-Type'];
  }

  const _options = {
    ...options,
    method: _method,
    headers: {
      ..._headler
    },
  }

  if (_method.toLocaleUpperCase() === 'POST') {
    _options.body = !!file ? formData : JSON.stringify(data);
  }

  return fetch(`${api}${getQuerys(params)}`, {
    ..._options,
  }).then((res) => {
      return res.json();
  });
};