import React from 'react';
import { VegaFlex, VegaChip, VegaText, VegaIcon, VegaButtonLink } from "@heartlandone/vega-react";
import { ACTIVITY_STATUS, ROUTES_PATH, MODULE } from 'Chore/Constants';
import dayjs from 'dayjs';
import imgSrc from "Imgs/activity_bg.png"
import { useNavigate} from 'react-router-dom';
import './module.css';

const { STATUS_LABEL } = ACTIVITY_STATUS;
const { MY_ACTIVITIES } = MODULE;
const { REGISTERED, PUBLISHED } = MY_ACTIVITIES;

function ActivityListItem(props) {
  const { activity, module } = props;
  const { address, chargeAmount, endTime, id, maxInvitedGuests, registerNumberLimit, startTime, title } = activity;
  const navigate = useNavigate();
  const _getCharge = (charge) => (charge <= 0 ? `Free` : `$ ${charge}`);
  const _showViewRegisterBtn = () => {
    return module === REGISTERED || module === PUBLISHED;
  }

  const _showCancelRegisterBtn = () => {
    return module === REGISTERED;
  }

  const _showEditRegisterBtn = () => {
    return module === PUBLISHED;
  }

  const _showCancelActivityBtn = () => {
    return module === PUBLISHED;
  }

  const _onViewActivityDetialClick = () => {
    if (module === REGISTERED) {
      navigate(`${ROUTES_PATH.MY_ACTIVITY_REGISTERED_DETAIL}`);
    } else if (module === PUBLISHED) {
      navigate(`${ROUTES_PATH.MY_ACTIVITY_PUBLISHED_DETAIL}/${id}`);
    }
  }

  return (
    <VegaFlex className='p-24 round-12 activity-list-item-border ' justifyContent="space-between">
      <VegaFlex direction={'col'} gap={'size-12'}>
        <VegaFlex gap={'size-8'}>
          <VegaChip text={STATUS_LABEL.OPEN} size={'extra-small'} bgColor='bg-chip' icon='user-group'></VegaChip>
          <VegaChip
            text={_getCharge(chargeAmount)}
            size={'extra-small'}
            bgColor={`${(_getCharge(chargeAmount) !== 'Free') ? 'bg-accent7-tertiary' : 'bg-chip'  }`}
            textColor={`${(_getCharge(chargeAmount) !== 'Free') ? 'text-accent7-primary' : ''}`}
            />
        </VegaFlex>
        <VegaFlex>
          <VegaText className="f-size-20 font-normal f-weight-700 line-height-28">{title}</VegaText>
        </VegaFlex>
        <VegaFlex className='font-light-text-color-secondary'>
          <VegaIcon
            icon="clock-face"
            size="size-16"
          ></VegaIcon>
          <VegaText className="m-l-12 f-size-14 font-normal f-weight-400 line-height-16">{dayjs(startTime).format('D/MMM/YYYY, H:m')} - {dayjs(endTime).format('D/MMM/YYYY, H:m')}</VegaText>
        </VegaFlex>
        <VegaFlex className='font-light-text-color-secondary'>
          <VegaIcon
            icon="fa-sharp fa-light fa-map-location"
            size="size-16"
          ></VegaIcon>
          <VegaText className="m-l-12 f-size-14 font-normal f-weight-400 line-height-16">{address}</VegaText>
        </VegaFlex>
        <VegaFlex className='font-light-text-color-secondary'>
          <VegaIcon
            icon="fa-light fa-users-viewfinder"
            size="size-16"
          ></VegaIcon>
          <VegaText className="m-l-12 f-size-14 font-normal f-weight-400 line-height-16">Participants (99/{ registerNumberLimit })</VegaText>
        </VegaFlex>
        <VegaFlex className='font-light-text-color-secondary'>
          <VegaIcon
            icon="fa-light fa-user-plus"
            size="size-16"
          ></VegaIcon>
          <VegaText className="m-l-12 f-size-14 font-normal f-weight-400 line-height-16">Max allow { maxInvitedGuests } Guest</VegaText>
        </VegaFlex>
        <VegaFlex className={"action-button"} gap={'size-24'}>
          {
            _showViewRegisterBtn() && 
              <VegaButtonLink
                label="View Registration Details"
                icon="fa-regular fa-eye"
                onVegaClick={() => _onViewActivityDetialClick()}
              />
          }
          {
            _showCancelRegisterBtn() && 
            <VegaButtonLink
              label="Cancel Register"
              icon="fa-solid fa-xmark"
            />
          }
          {
            _showEditRegisterBtn() && 
              <VegaButtonLink
              label="Edit Activity"
              icon="fa-solid fa-pen-to-square"
            />
          }
          {
            _showCancelActivityBtn() &&
            <VegaButtonLink
              label="Cancel Activity"
              icon="fa-solid fa-xmark"
            />
          }
        </VegaFlex>
      </VegaFlex>
      <VegaFlex>
        <div className='round-12' style={{
            backgroundImage: `url(${imgSrc})`,
            width: 280,
            height: 130,
            backgroundSize: 'contain',
        }}></div>
      </VegaFlex>
    </VegaFlex>
  );
}

export default ActivityListItem;
