import React from 'react';
import { VegaFlex} from "@heartlandone/vega-react";
import ActivityListItem from 'Components/ActivityListItem/ActivityListItem';

function ViewMyRegisteredActivityDetail() {

  return (
      <VegaFlex direction="col" gap="size-24" style={{ width: '100%' }} className='m-t-24 m-b-24'>
        <h2 className='m-0'>ActivityHub</h2>
         <ActivityListItem activity={{}} />
         <h2 className='m-0'>My Registration Details</h2>
      </VegaFlex>   
  );
}

export default ViewMyRegisteredActivityDetail;
