import React from "react";
import {
  VegaFlex,
  VegaInput,
  VegaIcon
} from "@heartlandone/vega-react";



function Guest({ index }) {
  return (
    <VegaFlex direction="col" style={{ width: '100%' }} className='m-t-20'>
       <VegaFlex direction="row" gap="size-24">
       <VegaFlex  direction="col" style={{ width: '95%' }} gap="size-24"><h3>Guest {index} </h3></VegaFlex> 
         <VegaFlex  direction="col" gap="size-24"  alignItems={"end"}><VegaIcon className='m-t-24' icon="fa-solid fa-trash-can" size="size-16"/></VegaFlex>
      </VegaFlex>
      <VegaFlex direction="row" gap="size-24"  alignItems={"end"}>
          <VegaFlex gap="size-24">
              <VegaFlex alignItems={"end"} gap="size-24"> <VegaInput label="Name" size="small" data-vega-form={"guestName"} required={true} style={{ width: 350 }}/>     
              <VegaInput label="PhoneNumber" size="small" data-vega-form={"guestPhoneNumber"} style={{width: 350}}/>                    
              </VegaFlex>
          </VegaFlex>
      </VegaFlex>
      <VegaFlex  direction="row" gap="size-24" alignItems={"end"}>
          <VegaInput label="Email" size="small" type="email" data-vega-form={"guestEmail"} style={{ width: 730 }}/>  
      </VegaFlex>
    </VegaFlex>  
  );
}

export default Guest ;
