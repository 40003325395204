import React, { useContext } from 'react';
import { VegaFlex, VegaCard, VegaIcon } from "@heartlandone/vega-react";
import {
  NavLink,
} from "react-router-dom";
import Forms from 'Forms/Form';
import ContentView from './ContentView';
import { HubContext } from 'Redux/Context/context';

function Root() {
  const { formState } = useContext(HubContext);

  return (
    <div className='relative' style={{ minHeight: 'calc(100vh - 252px)' }}>
      <VegaFlex gap={'size-16'} >
        <div>
          <VegaCard
            padding="size-12" style={{ width: 260 }} className={'round-12'}>
            <h6 className='f-size-12 f-weight-400 line-height-16 font-normal p-8-12 m-0'>TEAM GP</h6>
            <NavLink
              to={`/`}
              className={({ isActive, isPending }) =>
                isActive
                  ? "active"
                  : isPending
                  ? "pending"
                  : ""
            }>
              <div className={`f-size-16 f-weight-500 line-height-24 font-normal p-8-12 round-12 pointer bg-selected`}>
                <VegaIcon
                  icon="fa-solid fa-chart-network"
                  size="size-16"
                /><span className="m-l-12">GP Activity</span>
              </div>
            </NavLink>
            <NavLink
              to={`/my-activity`}
              className={({ isActive, isPending }) =>
                isActive
                  ? "active"
                  : isPending
                  ? "pending"
                  : ""
            }>
              <div className={`f-size-16 f-weight-500 line-height-24 font-normal p-8-12 round-12 pointer bg-selected`}>
                <VegaIcon
                  icon="fa-solid fa-inbox-full"
                  size="size-16"
                  style={{
                    padding: '0 2px 0 2px'
                  }}
                /><span className="m-l-12">My Activity</span>
              </div>
            </NavLink>
          </VegaCard>
        </div>
        <ContentView />
      </VegaFlex>
      <Forms name={formState.name}/>
    </div>
  );
}

export default Root;
