import React from 'react';
import ActivityForm from './ActivityForm';
import RegisterForm from './RegisterForm';

function Forms({ name = '' }) {

  if(name === 'ActivityForm')
  return (
    <>
      <ActivityForm name={name} />
    </>
  );
  else if (name === 'RegisterForm')
  return   (
      <>
        <RegisterForm name={name} />
      </>
    );
}

export default Forms;