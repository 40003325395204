import { SET_GLOBAL_ACTIVITIES } from '../Actions/actionName'

const GlobalActivitiesReducer = (state = '', action) => {
  switch (action.type) {
    case SET_GLOBAL_ACTIVITIES:
      return action.activities;
    default:
      return [];
  }
};

export default GlobalActivitiesReducer;
