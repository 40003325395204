import React from "react";
import {
  VegaChip,
  VegaFlex,
  VegaIcon,
  VegaText,
  VegaFont
} from "@heartlandone/vega-react";
import imgSrc from "Imgs/activity_bg.png";
import { useState, useEffect } from 'react';
import { PATH } from 'Chore/Apis';
const { ATTACHMENT } = PATH;


function DetailedItem({detailedForm = {}}) {
  const [isShownStartDateRange, setIsShownStartDateRange] = useState(false);
  const [isShownEndDateRange, setIsShownEndDateRange] = useState(false);

  useEffect(() => {
    let isStartDateValid = false;
    if(detailedForm?.startDate?.date && detailedForm?.startDate?.time)
    {
      isStartDateValid = true;
    }

    let isEndDateValid = false;
    if(detailedForm?.endDate?.date && detailedForm?.endDate?.time)
    {
      isEndDateValid = true;
    }
    
    setIsShownStartDateRange(isStartDateValid)
    setIsShownEndDateRange(isEndDateValid)
  }, [detailedForm?.startDate, detailedForm?.endDate]);


  const formatAmount = (number) =>{
    if(number === null || number === undefined || number === "")
      return '0';
    else 
      return number;
  }; 

  const showCoverLink = () => {
    if(detailedForm.coverSrcId > 0)
      return ATTACHMENT + '/' + detailedForm.coverSrcId;
    return imgSrc;
  }
  
  return (
    <VegaFlex
      className="v-border-divider-secondary v-px-size-32 v-py-size-16"
      gap={"size-16"}
      direction={"col"}
    >
      <img alt="failed" src={`${showCoverLink()}`}></img>
      <VegaText className="v-font-h4 v-text-success">${formatAmount(detailedForm?.charge?.value)}</VegaText>
      <VegaFlex direction={"col"} gap={"size-12"}>
        <div className="v-font-h4">{detailedForm.activityTitle}</div>
        <div className={`content-wrapper m-t-24${isShownStartDateRange && isShownEndDateRange ? "" : " display-none"}`} >
          <VegaFlex alignItems={"start"} gap={"size-12"}>
            <VegaIcon
              icon="clock-face"
              size="size-16"
              color="text-primary"
            ></VegaIcon>
            <span className="v-font-field-label-sm v-text-primary">
              {detailedForm?.startDate?.date} {detailedForm?.startDate?.time} - {detailedForm?.endDate?.date} {detailedForm?.endDate?.time}
            </span>
          </VegaFlex>
        </div>
        
        <VegaFlex alignItems={"start"} gap={"size-12"}>
          <VegaIcon
            icon="location-pin"
            size="size-16"
            color="text-primary"
          ></VegaIcon>
          <span className="v-font-field-label-sm v-text-primary">
            {detailedForm?.activityLocation?.city},{detailedForm?.activityLocation?.detail}
          </span>
        </VegaFlex>
        <VegaFlex alignItems={"start"} gap={"size-12"}>
          <VegaChip
            text={"Participants 0/" + formatAmount(detailedForm?.numberLimit?.value)}
            size={"small"}
            bgColor="bg-chip"
            icon="user-group"
          ></VegaChip>
        </VegaFlex>
        <VegaFlex alignItems={"center"} gap={"size-12"}>
          <VegaChip
            text={"Allow "+ formatAmount(detailedForm?.maxInvitedNumber) + " Guests"}
            size={"small"}
            bgColor="bg-chip"
          ></VegaChip>
        </VegaFlex>
      </VegaFlex>
      <VegaFlex direction={"col"} gap={"size-12"}>
          <div className="v-font-h6 content-wrapper">Activity Description</div> 
          <div>{detailedForm?.description}</div>
      </VegaFlex>
      <VegaFlex direction={"col"} gap={"size-12"}>
        <VegaText className="v-font-h6">Vedio Url</VegaText>
          <div>{detailedForm?.vedioUrl}</div>
      </VegaFlex>
      <VegaFlex direction={"col"} gap={"size-12"}>
        <div className="v-font-h6">Attachments</div>
        <VegaFont>GP Hachathon Recording1</VegaFont>
        <VegaFont>GP Hachathon Info</VegaFont>
        <VegaFont>GP Hachathon Requirement</VegaFont>
      </VegaFlex>
    </VegaFlex>
  );
}

export default DetailedItem;
