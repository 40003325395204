import { SET_My_ACTIVITIES } from '../Actions/actionName'

const MyActivitiesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_My_ACTIVITIES:
      return action.activities;
    default:
      return state;
  }
};

export default MyActivitiesReducer;
