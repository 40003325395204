import React, { useReducer } from 'react';
import { HubContext } from '../Context/context';
import formReducer from '../Reduecer/FormReducer';
import globalModalReducer from '../Reduecer/GlobalModalReducer';
import globalActivitiesReducer from '../Reduecer/GlobalActivitiesReducer';
import myActivitiesReducer from '../Reduecer/MyActivitiesReducer';

function HubContextProvider({ children }) {
  const [formState, formDispatch] = useReducer(formReducer, { name: '' });
  const [globalModalState, globalModalDispatch] = useReducer(globalModalReducer, { isShow: false });
  const [globalActivitiesState, globalActivitiesDispatch] = useReducer(globalActivitiesReducer, { activities: [] });
  const [myActivitiesState, myActivitiesDispatch] = useReducer(myActivitiesReducer, { activities: [] });
  return (
    <HubContext.Provider value={{
      formState,
      formDispatch,
      globalModalState,
      globalModalDispatch,
      globalActivitiesState,
      globalActivitiesDispatch,
      myActivitiesState,
      myActivitiesDispatch
       }}>
      {children}
    </HubContext.Provider>
  );
}

export default HubContextProvider;
