import React, { useState } from 'react';
import { VegaFlex } from "@heartlandone/vega-react";

const MainMenu = (props) => {
  const { menuGroup = []} = props;
  const [current, setCurrent] = useState(0);

  const _renderCurrent = (index) => {
    if (current === index) {
      return 'bg-light-primary';
    }

    return 'bg-action-quaternary';
  }

  const _onClick = (fn, {category, index}) => {
    setCurrent(index);
    fn(category, index);
  }

  const _renderMenuGroup = () => {
    return <VegaFlex gap={'size-8'} className='m-l-24 f-size-16 f-weight-500 line-height-22 font-light-text-color-primary'>
      {
        menuGroup.map((menu, index) => <div
          key={index}
          className={`p-8-16 round-t-8 pointer ${_renderCurrent(index)}`}
          onClick={() => _onClick(menu.callBack, {category: menu.category, index})}
        >
          {menu.label}
        </div>)
      }
    </VegaFlex>
  }

  return (
    <VegaFlex direction={'col'} style={{ width: 'calc(100vw - 390px)' }}>
      {
        _renderMenuGroup()
      }
      {props.children}
    </VegaFlex>
  );
}

export default MainMenu;
