import React, { useContext, useEffect } from 'react';
import { HubContext } from 'Redux/Context/context';
import { VegaGrid } from "@heartlandone/vega-react";
import ListItem from './ListItem'

function List() {
    const { globalActivitiesState } = useContext(HubContext);
    
    useEffect(() => {
        console.info(globalActivitiesState.activities);
    }, [globalActivitiesState.activities])

    return (
        <VegaGrid column={3} row={2} gap={'size-16'}>
            {globalActivitiesState.activities?.map((activity, index) => {
                return <ListItem key={index} activity={activity}></ListItem>;
            })}
        </VegaGrid>
    );
}

export default List;
