import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  RouterProvider
} from "react-router-dom";
import ROUTES from './router';
import HubContextProvider from 'Redux/Provider/HubContextProvider';
import reportWebVitals from './reportWebVitals';
import '@heartlandone/vega/style/vega.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HubContextProvider>
    <RouterProvider router={ROUTES} />
  </HubContextProvider>
);

(function (){
  const url = window.location.href;
  const keyword = 'token=';
  const index = url.indexOf(keyword);
  const token = url.substring(`${index + keyword.length}`);
  sessionStorage.setItem('token', `Bearer ${token}`);
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
