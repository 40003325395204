import React from 'react';
import { VegaFlex, VegaInputSelect, VegaInput } from "@heartlandone/vega-react";
import { FILTER_CONTROL_CATEGORY } from 'Chore/Constants';

const FilterGroup = ({ options = [] }) => {
  const _renderInputs = () => {
    return options.map(({
      type = FILTER_CONTROL_CATEGORY.INPUT,
      prefixIcon = 'fa-duotone fa-magnifying-glass',
      isRequired = false,
      placeholder = '',
      source,
      onChangeHandler = () => {}
    }, index) => {
      if (type === FILTER_CONTROL_CATEGORY.INPUT) {
        return <VegaInput
          key={index}
          prefixIcon={prefixIcon}
          required={isRequired}
          placeholder={placeholder}
          onVegaChange={onChangeHandler}
        />
      } else if (type === FILTER_CONTROL_CATEGORY.SELECT) {
        return <VegaInputSelect
          key={index}
          prefixIcon={prefixIcon}
          required={isRequired}
          placeholder={placeholder}
          source={source}
          onVegaChange={onChangeHandler}
        />
      } else {
        return null;
      }
    })
  }

  return (
    <VegaFlex gap={'size-16'} style={{ width: '75%' }}>
      {_renderInputs()}
    </VegaFlex>
  );
}

export default FilterGroup;
