import React, { useContext, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import { HubContext } from 'Redux/Context/context';
import { toggleGlobalModalAction } from 'Redux/Actions/actions';
import { _fetch } from 'Chore/Request';
import Avatar from 'Components/Avatar';
import { PAGINATION_OPTIONS } from 'Chore/Constants';
import { VegaFlex, VegaIcon, VegaButtonLink, VegaChip, VegaDivider, VegaTable, VegaPagination } from "@heartlandone/vega-react";
import dayjs from 'dayjs';
import { PATH } from 'Chore/Apis';

const { GET_ACTIVITY } = PATH;
const { ACTIVITY_GUEST_LIST } = PAGINATION_OPTIONS;

function ViewMyPublishedActivityDetail() {
  const { globalModalDispatch } = useContext(HubContext);
  const params = useLoaderData();
  const [total, setTotal] = useState(0);
  const [guests, setGuests] = useState([{
    key: "1",
    name: "Mr White",
    email: 'John.White@e-hps.com',
    phone: "14234234324",
    note: "This is my first activity",
  }]);

  useEffect(() => {
    const { id } = params;
    _loadActivityRegisterInfo({id})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOnline = (item) => {
    if ( item ==='true'){
       return "Online";
    }
    else{
       return "Offline";
    }
  };

  const _toggleModal = (isShow = false) => {
    globalModalDispatch(toggleGlobalModalAction({isShow}));
  }

  const _loadActivityRegisterInfo = ({
    id,
  }) => {
    _toggleModal(true);
    _fetch({
      api: `${GET_ACTIVITY}/${id}/registers`,
    }).then((res) => {
      setGuests(res?.payload?.items);
      const timer = setTimeout(() => {
        setTotal(res?.payload?.totalCount);
        clearTimeout(timer);
      }, 200);
    }).finally(() => {
      _toggleModal();
    })
  }

  const _onPageIndexClick = (e) => {
    _loadActivityRegisterInfo({
      pageindex: e.detail,
      pagesize: ACTIVITY_GUEST_LIST.SPAN
    });
  }

  return (
    <VegaFlex direction="col" gap="size-24" style={{ width: '100%' }} className='m-t-24 m-b-24'>
      <VegaFlex>
        <VegaButtonLink
          label="Back"
          icon="fa-solid fa-angle-left"
          onVegaClick={() => window.history.back()}
        />
      </VegaFlex>
      <VegaFlex direction={'col'} gap={'size-12'}>
        <VegaFlex direction={'col'} style={{ width: '90%' }}>
          <h1 className='m-0'>2023 GP 1024 Hackathon Festival</h1>
        </VegaFlex>
        <VegaFlex alignItems={"center"} gap={'size-32'}>
          <div>
            <Avatar width='size-20' avatar='https://images.unsplash.com/photo-1681015162686-79e9cd18a0e6?auto=format&fit=max&w=200&q=80'  name="chole chen"></Avatar>
          </div>
          <div>
            <span className='m-0'>Chole Chen</span>
          </div>
          <div>
            <VegaIcon size={'size-16'} icon='fa-thin fa-clock'></VegaIcon>
            <span className='m-l-12'>{dayjs().format('D/MMM/YYYY, HH:mm')} - {dayjs().format('DD/MMM/YYYY, HH:mm')}</span>
          </div>
          <div>
            <VegaIcon size={'size-16'} icon='fa-thin fa-map-location-dot'></VegaIcon>
            <span className='m-l-12'>{isOnline('true')}</span>
          </div>
          <div>
            <VegaChip text={'New'} size={'extra-small'} bgColor='bg-chip'></VegaChip>
          </div>
        </VegaFlex>
        <VegaDivider />
          <VegaFlex direction={'col'}>
            <VegaFlex justifyContent={'space-between'} alignItems={'center'}>
              <h2>Registration Details</h2>
              <VegaButtonLink
                label="Export Form"
                icon="fa-solid fa-arrow-down-to-line"
              />
            </VegaFlex>
            <VegaTable
              columns={[
                {
                  label: "Name",
                  prop: "name",
                }, {
                  label: "Phone Number",
                  prop: "phone",
                }, {
                  label: "Email",
                  prop: "email",
                }, {
                  label: "Note",
                  prop: "note",
                },
              ]}
              dataSource={guests}
            />
          </VegaFlex>
          <VegaFlex  className={`m-t-16`} justifyContent='end'>
            <VegaPagination
              total={total}
              pageSize={ACTIVITY_GUEST_LIST.SPAN}
              extensions={[
                ["currentPageLabel", "pageSelector"],
              ]}
              onVegaChange={_onPageIndexClick}
            />
          </VegaFlex>
      </VegaFlex>
    </VegaFlex>
  );
}

export default ViewMyPublishedActivityDetail;
