import { BackgroundColors } from '@heartlandone/vega';

export const saveToLocalStorage = (key, data) => {
  const _ = JSON.stringify(data);
  localStorage.setItem(key, _);
}

export const loadFromLocalStorage = (key, _ = {}) => {
  return JSON.parse(localStorage.getItem(key)) || _;
}

export const saveToSessionStorage = (key, data) => {
  const _ = JSON.stringify(data);
  sessionStorage.setItem(key, _);
}

export const loadFromSesionStorage = (key, _ = {}) => {
  return JSON.parse(sessionStorage.getItem(key)) || _;
}

export const getQuerys = (querys = {}) => {
  const keys = Object.keys(querys);
 
  if (keys.length < 1) {
    return '';
  } else {
    return `?${new URLSearchParams(querys).toString()}`;
  }
}

export const getCategory = (category = 'registered', isGPActivities = false) => {
  return isGPActivities ? '' : `/${category}`;
}

export const getShortName = (str) => {
  return str?.split(' ').map((item) => {
    return item?.slice(0, 1).toUpperCase();
  }).join('');
}

export const getBackgroundByShortName = (str) => {
  if (str) {
    const letter2num = { a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8, i: 9, j: 10, k: 11, l: 12, m: 13, n: 14, o: 15, p: 16, q: 17, r: 18, s: 19, t: 20, u: 21, v: 22, w: 23, x: 24, y: 25, z: 26 };
    let total = 0;
    for (let index = 0; index < str.length; index++) {
      total += letter2num[str[index].toLowerCase()];
    }
    // use 40+ colors
    return BackgroundColors[total + 40];
  }
  return BackgroundColors[40];
}