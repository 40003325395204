import React from "react";
import {
  VegaFlex,
  VegaToggleSwitch,
  VegaCheckboxGroup,
  VegaCheckbox,
  VegaFont,
  VegaInput,
  VegaForm,
} from "@heartlandone/vega-react";

const SECOND_STEP = 1;

function StepTwo({
  currentStep,
  callback = () => {},
  fields: { isAllowedCollectInfo = false, isAllowedInviteGuests = false },
}) {
  const _isShow = () => currentStep === SECOND_STEP;

  return (
    <div className={`m-t-32${_isShow() ? "" : " display-none"}`}>
      <div className="content-wrapper m-t-24">
        <VegaFlex direction="col" gap="size-20">
          <VegaToggleSwitch
            size="default"
            label="This activity will be collect of information about the participants (By default, the system collects user names)"
            data-vega-form={"isAllowedCollectInfo"}
            onVegaChange={(e) =>
              callback("isAllowedCollectInfo", e?.detail || false)
            }
            checked={isAllowedCollectInfo}
          />
          <div className={`m-t-32${isAllowedCollectInfo ? "" : " display-none"}`}>
            <VegaFlex
              alignItem="center"
              className="p-t-30 p-b-8"
            >
              <VegaForm data-vega-form={`guestInfo`}>
                <VegaCheckboxGroup
                  vegaFlexProp={{
                    direction: "col",
                  }}
                  value={["username"]}
                >
                  <VegaFlex justifyContent="space-between">
                    <VegaFont gap="size-24">User Name</VegaFont>
                    <VegaCheckbox
                      identifier="username"
                      className="p-x-128"
                      label="username"
                      data-vega-form={"username"}
                    >
                      Checked as a required field
                    </VegaCheckbox>
                  </VegaFlex>

                  <VegaFlex justifyContent="space-between">
                    <VegaFont gap="size-24">Phone</VegaFont>
                    <VegaCheckbox
                      identifier="phone"
                      className="p-x-128"
                      label="phone"
                      data-vega-form={"phone"}
                    >
                      Checked as a required field
                    </VegaCheckbox>
                  </VegaFlex>

                  <VegaFlex justifyContent="space-between">
                    <VegaFont gap="size-24">Email</VegaFont>
                    <VegaCheckbox
                      identifier="email"
                      className="p-x-128"
                      label="email"
                      data-vega-form={"email"}
                    >
                      Checked as a required field
                    </VegaCheckbox>
                  </VegaFlex>

                  <VegaFlex justifyContent="space-between">
                    <VegaFont gap="size-24">Note</VegaFont>
                    <VegaCheckbox
                      identifier="note"
                      className="p-x-128"
                      label="note"
                      data-vega-form={"note"}
                    >
                      Checked as a required field
                    </VegaCheckbox>
                  </VegaFlex>
                </VegaCheckboxGroup>
              </VegaForm>
            </VegaFlex>
          </div>

          <VegaFlex className="m-t-32" gap="size-24">
            <VegaForm>
              <VegaToggleSwitch
                size="default"
                label="Allow registers to invite guests"
                data-vega-form={"isAllowedInviteGuests"}
                onVegaChange={(e) =>
                  callback("isAllowedInviteGuests", e?.detail || false)
                }
                checked={isAllowedInviteGuests}
              />

              <div
                className={`m-t-32${
                  isAllowedInviteGuests ? "" : " display-none"
                }`}
              >
                <VegaInput
                  type="number"
                  size="small"
                  style={{
                    width: 80,
                  }}
                  data-vega-form={"maxInvitedNumber"}
                />
                <VegaFont className="p-x-16">
                  guests are maxmimum invited
                </VegaFont>
              </div>
            </VegaForm>
          </VegaFlex>
        </VegaFlex>
      </div>
    </div>
  );
}

export default StepTwo;
