import React, { useContext } from 'react';
import Root from "Views/Root";
import { HubContext } from 'Redux/Context/context';
import { VegaAppFooter, VegaFlex, VegaAppHeaderButton, VegaLoadingIndicator } from "@heartlandone/vega-react";
import './App.css';

function App() {
  const { globalModalState } = useContext(HubContext);
  return (
    <>
      <VegaFlex id='global-modal' className={`${globalModalState.isShow ? '' : 'display-none'} absolute top-floor w-full h-full backdrop-Modal`} justifyContent="center" alignItems="center">
        <VegaLoadingIndicator mode={`indeterminate`}/>
      </VegaFlex>
      <div id="app-root" style={{ width: '100%', height: '100vh' }} className="box-border p-x-32">
        <VegaFlex justifyContent="space-between" alignItem="center" className="p-t-32 p-b-24" style={{ height: 68 }}>
          <VegaFlex alignItem="center" gap={'size-12'}>
            <span className="f-size-28 f-weight-700 line-height-36">Activity Hub</span>
          </VegaFlex>
          <VegaAppHeaderButton
            label="Andrea Vega"
            avatar={{
              "src": "https://images.unsplash.com/photo-1681015162686-79e9cd18a0e6?auto=format&fit=max&w=200&q=80",
              "fallbackText": "AV"
            }}
            dropdown={{
              "source": [
                {
                  "key": "opt0",
                  "label": "My Account"
                },
                {
                  "key": "opt1",
                  "label": "Settings"
                },
                {
                  "key": "opt2",
                  "label": "Notifications"
                },
                {
                  "key": "opt3",
                  "label": "Sign Out"
                }
              ]
            }}
          />
        </VegaFlex>
        <Root />
        <VegaAppFooter
          additionalLinks={[
            {
              "label": "Contact Us",
              "URL": "mailto:vega@e-hps.com"
            }
          ]}
          style={{
            paddingTop: 64
          }}
        />
      </div>
    </>
  );
}

export default App;
