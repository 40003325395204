import React from "react";
import DetailedItem from "Views/ActivityList/DetailedItem";
import { VegaFlex } from "@heartlandone/vega-react";

const THIRD_STEP = 2;

function StepThree({ currentStep, detailedForm = {} }) {
  const _isShow = () => currentStep === THIRD_STEP;
  return (
    <div className={`m-t-32${_isShow() ? "" : " display-none"}`}>
      <VegaFlex justify-content="center" className="p-t-32 p-b-24">
          <DetailedItem detailedForm ={detailedForm} />
      </VegaFlex>
    </div>
  );
}

export default StepThree;
