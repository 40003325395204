export const registedDropdownOptions = [
  {
    "id": "unlimited",
    "displayName": "unlimited"
  },
  {
    "id": "limited",
    "displayName": "limited"
  }
]

export const chargeDropdownOptions = [
  {
    "id": "paid",
    "displayName": "paid"
  },
  {
    "id": "free",
    "displayName": "free"
  }
]

export const locationDropdownOptions = [
  {
    "id": "Xian",
    "displayName": "Xian"
  },
  {
    "id": "ChengDu",
    "displayName": "ChengDu"
  }
]

export const FORM_NAME = {
  ACTIVITY_FORM: 'ActivityForm',
  REGISTER_FORM: 'RegisterForm'
}

export const ACTIVITY_STATUS = {
  STATUS_LABEL: {
    OPEN: 'open',
  },
}

export const FILTER_CONTROL_CATEGORY = {
  INPUT: 'input',
  SELECT: 'select'
}

export const ROUTES_PATH = {
  ROOT: '/',
  MY_ACTIVITY: '/my-activity',
  ACTIVITY_INFO: '/activityInfo/:activityId',
  MY_ACTIVITY_REGISTERED_DETAIL: '/activityInfo/registered',
  MY_ACTIVITY_PUBLISHED_DETAIL: '/activityInfo/published',
}

export const DYNAMIC_SEGMENT = {
  ID: '/:id'
}

export const SHOW_TYPE = {
  ACTIVITY_VIEW_REGISTERED : 'activity_registered_view_detail'
}

export const PAGINATION_OPTIONS = {
  GP_LIST: {
    SPAN: 6
  },
  ACTIVITY_GUEST_LIST: {
    SPAN: 10
  }
};

export const MODULE = {
  MY_ACTIVITIES: {
    REGISTERED: 'REGISTERED',
    PUBLISHED: 'PUBLISHED',
    FAVORITE: 'FAVORITE'
  }
}

export const FILTER_OPTIONS = {
  PERIOD: [
    {
    "id": "0",
    "displayName": "All Time"
    },
    {
      "id": "1",
      "displayName": "Last 1 Year"
    },
    {
      "id": "2",
      "displayName": "Last 6 Months"
    },
    {
      "id": "3",
      "displayName": "Last 3 Months"
    }
  ],
  PAYMENT: [
    {
      "id": "0",
      "displayName": "Free + Paid"
    }, {
      "id": "1",
      "displayName": "Free"
    }, {
      "id": "2",
      "displayName": "Paid"
    }
  ]
}
