import React, { useContext, useEffect, useState } from 'react';
import { VegaFlex, VegaButtonLink, VegaChip, VegaIcon, VegaFont,VegaButton } from "@heartlandone/vega-react";
import { useLocation} from 'react-router-dom';
import Avatar from 'Components/Avatar';
import Carousel from 'Components/Carousel/Carousel';
import './ActivityList/module.css';
import { HubContext } from 'Redux/Context/context';
import { FORM_NAME } from 'Chore/Constants';
import { openFormAction } from 'Redux/Actions/actions';
import {_fetch} from 'Chore/Request';
import { PATH } from 'Chore/Apis';
import dayjs from 'dayjs';

const { GET_ACTIVITY } = PATH; 
const { POST_ACTIVITY } = PATH; 

function ActivityDetail() {
  const location = useLocation();

  const { formDispatch } = useContext(HubContext);
  const [isFavorite, setIsFavorite] = useState(false);
  const [activityInfo, setCurrentActivityInfo] = useState({});
  const {id = 0, participantsCount, status, isOnlineActivity, registerSetting, chargeAmount = 0, startTime, endTime, description, registetNumberLimit = 0, title, registerDeadline} = activityInfo;

  const isOnline = (item) => {
    if ( item ==='true'){
       return "Online";
    }
    else{
       return "Offline";
    }
  };

  const _toggleFavoriteState = (id) => {

    let favoriteApiPath = `${POST_ACTIVITY}/${id}/favorite`;

    if(isFavorite === true) {
      favoriteApiPath = `${POST_ACTIVITY}/${id}/unfavorite`;
    }

    _fetch({
      api: favoriteApiPath,
      method: 'POST'
    }).then(() => {
       setIsFavorite(!isFavorite);
    }).finally(() => {
      
    })
  };

  const OnRigisterButtonClick = (e) => {
    e.preventDefault();
    formDispatch(openFormAction({ name: FORM_NAME['REGISTER_FORM'] }));
 }

  useEffect(() => {
     const activityId = location.state?.activityId;
    _fetch({
      api: `${GET_ACTIVITY}/${activityId}`
    })
    .then(res => {
      var activity= res?.payload;
      setCurrentActivityInfo(activity);
      setIsFavorite(activity.isFavorite);
    });
  }, [location]);


 const formatAmount = (number) =>{
  if(number === null || number === undefined || number === "")
    return '0';
  else 
    return number;
 }; 

  return (
    <VegaFlex direction={'col'}>
      <VegaFlex className='m-b-24'>
        <VegaButtonLink
            label="Back"
            icon="fa-solid fa-angle-left"
            onVegaClick={() => window.history.back()}
        />
      </VegaFlex>
      <VegaFlex direction={'col'} gap={'size-12'} className='m-b-24'>
        <VegaFlex direction={'row'} >
          <VegaFlex direction={'col'} style={{ width: '90%' }}>
          <h1 className='m-0'>{title}</h1>
          </VegaFlex>
          <VegaFlex direction={'col'} style={{ width: '10%' }}>
           <VegaButton label="Regist Now" size="small" iconAlign="right" onVegaClick={OnRigisterButtonClick} />
          </VegaFlex>
        </VegaFlex>
        <VegaFlex alignItems={"center"} gap={'size-32'}>
          <div>
            <Avatar width='size-20' avatar='https://images.unsplash.com/photo-1681015162686-79e9cd18a0e6?auto=format&fit=max&w=200&q=80'  name="chole chen"></Avatar>
          </div>
          <div>
            <span className='m-0'>Chole Chen</span>
          </div>
          <div>
            <VegaIcon size={'size-16'} icon='fa-thin fa-clock'></VegaIcon>
            <span className='m-l-12'>{dayjs(startTime).format('D/MMM/YYYY, HH:mm')} - {dayjs(endTime).format('DD/MMM/YYYY, HH:mm')}</span>
          </div>
          <div>
            <VegaIcon size={'size-16'} icon='fa-thin fa-map-location-dot'></VegaIcon>
            <span className='m-l-12'>{isOnline(isOnlineActivity)}</span>
          </div>
          <div>
            <VegaChip text={status} size={'extra-small'} bgColor='bg-chip'></VegaChip>
          </div>
        </VegaFlex>
      </VegaFlex>
      <VegaFlex>
      <Carousel />
      </VegaFlex>
      <VegaFlex gap={'size-24'}  className='m-t-32'>
        <VegaFlex direction={'col'} gap={'size-24'} style={{ width: '75%' }}>
          <VegaFlex style={{ width: '100%' }} justifyContent={'space-between'}>
            <h4 className='m-0'>Activity Description</h4>
            <VegaFlex gap={'size-32'}>
              <VegaIcon
                icon="fa-solid fa-heart"
                size="size-16"
                onClick={_toggleFavoriteState({id})}
                // className={`${isFavorite ? 'font-dark-Background-Accent6-Primary' : 'font-light-Background-Toogle-Switch'}`}
              />
              <VegaIcon
                icon="fa-regular fa-share-nodes"
                size="size-16"
              />
            </VegaFlex>
          </VegaFlex>
          <VegaFlex>
             {description}
          </VegaFlex>
          <VegaFlex style={{ width: '70%' }} justifyContent={'space-between'}>
            <h4 className='m-0'>Activity Files</h4>
          </VegaFlex>
          <VegaFlex direction={"row"} justifyContent={'space-between'} style={{ width: '80%' }}>
             <VegaFlex direction = {"col"} style={{ width: '80%' }}>
               <VegaFont>GP Hachathon Recording1</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}>
                <VegaFont>30.00 MB</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}>
               <VegaIcon icon="far fa-download" size="size-20" color="text-link"/>
            </VegaFlex> 
          </VegaFlex>
          <VegaFlex direction={"row"} style={{ width: '80%' }}>
             <VegaFlex direction = {"col"} style={{ width: '80%' }}>
               <VegaFont>GP Hachathon Recording1</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}>
                <VegaFont>30.00 MB</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}>
               <VegaIcon icon="far fa-download" size="size-20" color="text-link"/>
            </VegaFlex> 
          </VegaFlex>
          <VegaFlex direction={"row"} style={{ width: '80%' }}>
             <VegaFlex direction = {"col"} style={{ width: '80%' }}>
               <VegaFont>GP Hachathon Recording1</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}>
                <VegaFont>30.00 MB</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}>
               <VegaIcon icon="far fa-download" size="size-20" color="text-link"/>
            </VegaFlex>  
          </VegaFlex>
          <VegaFlex direction={"row"} style={{ width: '80%' }}>
             <VegaFlex direction = {"col"} style={{ width: '80%' }}>
               <VegaFont>GP Hachathon Recording1</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}>
                <VegaFont>30.00 MB</VegaFont>
            </VegaFlex> 
            <VegaFlex direction = {"col"} style={{ width: '10%' }}  >
               <VegaIcon icon="far fa-download" size="size-20" color="text-link"/>
            </VegaFlex> 
          </VegaFlex>
        </VegaFlex>
        <VegaFlex direction={'col'}  style={{ width: '25%' }} gap={'size-24'}>
          <VegaFlex className='meta-data p-24 bg-light-Background-Secondary round-12' gap={'size-16'} alignItems={'flex-start'}>
            <VegaIcon size={'size-24'} icon='fa-duotone fa-solid fa-hourglass-clock'></VegaIcon>
            <VegaFlex direction={'col'} gap={'size-8'}>
              <span className='f-weight-700 line-height-18 f-size-14 font-light-text-color-primary'>{dayjs(registerDeadline).format('DD/MMM/YYYY, HH:mm')}</span>
              <span className='f-weight-400 line-height-16 f-size-14 ont-light-text-color-secondary'>Register Deadline</span>
            </VegaFlex>
          </VegaFlex>
          <VegaFlex className='meta-data p-24 bg-light-Background-Secondary round-12' gap={'size-16'} alignItems={'flex-start'}>
            <VegaIcon size={'size-24'} icon='fa-duotone fa-solid fa-coins'></VegaIcon>
            <VegaFlex direction={'col'} gap={'size-8'}>
              <span className='f-weight-700 line-height-18 f-size-14 font-light-text-color-primary'>{formatAmount(chargeAmount)}</span>
              <span className='f-weight-400 line-height-16 f-size-14 ont-light-text-color-secondary'>Charge</span>
            </VegaFlex>
          </VegaFlex>
          <VegaFlex className='meta-data p-24 bg-light-Background-Secondary round-12' gap={'size-16'} alignItems={'flex-start'}>
            <VegaIcon size={'size-24'} icon='fa-solid fa-users-viewfinder'></VegaIcon>
            <VegaFlex direction={'col'} gap={'size-8'}>
              <span className='f-weight-700 line-height-18 f-size-14 font-light-text-color-primary'>Participants({participantsCount}/{registetNumberLimit})</span>
              <span className='f-weight-400 line-height-16 f-size-14 ont-light-text-color-secondary'>Reigist Limit</span>
            </VegaFlex>
          </VegaFlex>
          <VegaFlex className='meta-data p-24 bg-light-Background-Secondary round-12' gap={'size-16'} alignItems={'flex-start'}>
            <VegaIcon size={'size-24'} icon='fa-duotone fa-solid fa-user-plus'></VegaIcon>
            <VegaFlex direction={'col'} gap={'size-8'}>
              <span className='f-weight-700 line-height-18 f-size-14 font-light-text-color-primary'> 
                {
                   registerSetting?.isAllowInviteGuests && `Allow ${registerSetting?.maxInvitedGuests} Guests`
                }
              </span>
              <span className='f-weight-400 line-height-16 f-size-14 ont-light-text-color-secondary'>Invite Guest</span>
            </VegaFlex>
          </VegaFlex>
        </VegaFlex>
      </VegaFlex>
    </VegaFlex>
  );
}

export default ActivityDetail;
